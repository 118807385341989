<template>
  <base-material-card
    icon="far fa-users"
    title="Mannschaftsübersicht"
    color="primary"
  >
    <p>Die Listen können als Korrektur für die einzelnen Mannschaften ausgedruckt werden.</p>
    <p>Die bereits eingegebenen Wertungen werden automatisch mit in die Liste gedruckt.</p>
    <v-btn block @click="reihenfolge(e, df, r, tids)">Download</v-btn>
  </base-material-card>
</template>

<script>
import { usePdf } from '@/views/components/stb_w_2023/plugins/pdf'
import { mapGetters } from 'vuex'

export default {
  name: 'mannschaftsuebersicht',

  setup (props, context) {
    return {
      ...usePdf(context)
    }
  },

  props: {
    id: {
      type: String,
      required: true
    },
    e: {
      type: Object,
      required: true
    },
    r: {
      type: Array,
      required: true
    },
    df: {
      type: Array,
      required: true
    }
  },

  computed: {
    ...mapGetters(['isMaster', 'isTeam', 'isStbWWk']),
    tids () {
      return this.e?.teams?.filter(t => this.isMaster || this.isStbWWk(this.id) || this.isTeam(t.team._id))?.map(t => t.team._id) || []
    }
  }
}
</script>

<style scoped>

</style>
